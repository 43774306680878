// BH Starter Version *** DONT CHANGE ***
// Version: 2.0.9

export const environment = {
  // Environment Name
  env: 'DEV',

  // Is Production Environment?
  production: false,

  // App Name/ID
  appName: 'PATGATEWAY',

  // App Description (full name)
  appDescription: 'MyBaystate',

  // App Version Number
  appVersion: '14.0.0',

  // App's REST Service API Root URL
  apiUrl: 'https://mobiledev.api.baystatehealth.org/mybaystate',

  // App's Uploaded Files Root URL
  filesUrl: 'https://dev.mybaystate.baystatehealth.org/uploaded-images',

  // Verlocker API URL
  verlockerUrl: 'https://mobiledev.api.baystatehealth.org/verlocker',

  // Patient Portal URL
  portalUrl: 'https://dev.mybaystate.baystatehealth.org',

  // BH AppStore Deep Link URL
  appstoreUrl: 'https://mobile.baystatehealth.org/appstore/',

  // Apple AppStore URL
  appleAppStoreUrl: 'https://itunes.apple.com/us/app/mybaystatehealth/id1149098574?mt=8',

  // Google Play URL
  googlePlayUrl: 'https://play.google.com/store/apps/details?id=org.baystatehealth.mybaystatehealth',

  // Reset password link URL
  resetPwdUrl: 'https://baystatehealth.service-now.com/nav_to.do?uri=%2F$pwd_reset.do%3Fsysparm_url%3Dpassword_reset',

  // Change password security questions link URL
  changePwdQuestionsUrl: 'https://baystatehealth.service-now.com/b_sp?id=pwd_reset_enrollment',

  // Set default theme: auto, light, dark
  // Auto will select theme based on browser's preferred theme
  theme: 'light',

  // Store token in local storage to remember user?
  // Patient/Secure Apps should set this to false
  storeToken: true,

  // Require PIN code enabled on device
  // Patient/Secure Apps will require that the PIN code is enabled
  // If PIN is not in use, app will block user
  requirePin: true,

  // Require timeout
  // Patient/Secure Apps will require that timeout is enabled
  // On timeout, user will be bumped
  requireTimeout: false,

  // Timeout threshold
  // Determine the number of seconds when timeout will occur
  // 15 minutes = 900000
  timeoutThreshold: 900000,
};

export const apiBaseUrl = 'https://mytest.baystatehealth.org/myhealth/api/v1/';